html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'EarlSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--tw-white);
}

:root {
  --tw-primary-shade: #00524F;
  --tw-primary-dark: #77CCD1;
  --tw-primary: #7BD6DC;
  --tw-primary-light: #cde9f1;
  --tw-primary-tint: #DCF3FA;

  --tw-green-shade: #003A00;
  --tw-green-dark: #00893F;
  --tw-green: #00C45B;
  --tw-green-light: #4CD58C;
  --tw-green-tint: #E5FFE4;

  --tw-blue-shade: #011461;
  --tw-blue-dark: #314AB2;
  --tw-blue: #476BFF;
  --tw-blue-light: #7E97FF;
  --tw-blue-tint: #D9E6FF;

  --tw-yellow-shade: #785200;
  --tw-yellow-dark: #A98700;
  --tw-yellow: #F2C200;
  --tw-yellow-light: #F5D44C;
  --tw-yellow-tint: #FFF3B8;

  --tw-orange-shade: #613400;
  --tw-orange-dark: #B25100;
  --tw-orange: #FF7500;
  --tw-orange-light: #FF9E4C;
  --tw-orange-tint: #FFE4AB;

  --tw-red-shade: #7D1900;
  --tw-red-dark: #A82A09;
  --tw-red: #F03D0E;
  --tw-red-light: #F47756;
  --tw-red-tint: #FFD4C4;

  --tw-gray-shade: #292929;
  --tw-gray-dark: #66686A;
  --tw-gray: #939598;
  --tw-gray-light: #B3B4B6;
  --tw-gray-tint: #DEDFE0;

  --tw-pink-shade: #52112D;
  --tw-pink-dark: #B23C61;
  --tw-pink: #FF578B;
  --tw-pink-light: #FF89AD;
  --tw-pink-tint: #F7DEF4;

  --tw-purple-shade: #4D1773;
  --tw-purple-dark: #823EA5;
  --tw-purple: #BA5AED;
  --tw-purple-light: #CE8BF2;
  --tw-purple-tint: #EDD4FF;

  --tw-white: #FFFFFF;

  --tw-black: #000000;

  --bs-primary-bg: var(--tw-primary-shade);
  --bs-primary-bg-subtle: var(--tw-primary);
  --bs-primary-border-subtle: var(--tw-primary);

  --bs-link-color: var(--tw-primary-shade);
  --bs-link-hover-color: #0a7a76;
  --bs-btn-disabled-color: var(--tw-gray-dark);

  --bs-border-radius: 10px;
}

:root,
[data-bs-theme=light] {

  --bs-primary: var(--tw-primary-shade) !important;
  --bs-primary-subtle: var(--tw-primary) !important;
  --bs-primary-border-subtle: var(--tw-primary) !important;
  --bs-emphasis-color: var(--tw-primary-shade);
  --bs-nav-link-color: var(--tw-gray-shade);
}


.color-primary { color: var(--tw-primary) !important; }
.color-primary-shade { color: var(--tw-primary-shade) !important; }
.color-primary-tint { color: var(--tw-primary-tint) !important; }

.color-green { color: var(--tw-green) !important; }
.color-green-dark { color: var(--tw-green-dark) !important; }
.color-green-shade { color: var(--tw-green-shade) !important; }
.color-green-tint { color: var(--tw-green-tint) !important; }

.color-blue { color: var(--tw-blue) !important; }
.color-blue-shade { color: var(--tw-blue-shade) !important; }
.color-blue-tint { color: var(--tw-blue-tint) !important; }

.color-yellow { color: var(--tw-yellow) !important; }
.color-yellow-dark { color: var(--tw-yellow-dark) !important; }
.color-yellow-shade { color: var(--tw-yellow-shade) !important; }
.color-yellow-tint { color: var(--tw-yellow-tint) !important; }

.color-orange { color: var(--tw-orange) !important; }
.color-orange-shade { color: var(--tw-orange-shade) !important; }
.color-orange-tint { color: var(--tw-orange-tint) !important; }

.color-red { color: var(--tw-red) !important; }
.color-red-dark { color: var(--tw-red-dark) !important; }
.color-red-shade { color: var(--tw-red-shade) !important; }
.color-red-tint { color: var(--tw-red-tint) !important; }

.color-gray { color: var(--tw-gray) !important; }
.color-gray-shade { color: var(--tw-gray-shade) !important; }
.color-gray-tint { color: var(--tw-gray-tint) !important; }

.color-pink { color: var(--tw-pink) !important; }
.color-pink-shade { color: var(--tw-pink-shade) !important; }
.color-pink-tint { color: var(--tw-pink-tint) !important; }

.color-purple { color: var(--tw-purple) !important; }
.color-purple-shade { color: var(--tw-purple-shade) !important; }
.color-purple-tint { color: var(--tw-purple-tint) !important; }

.color-white { color: var(--tw-white) !important; }

.bg-primary { background-color: var(--tw-primary) !important; }
.bg-primary-shade { background-color: var(--tw-primary-shade) !important; }
.bg-primary-tint { background-color: var(--tw-primary-tint) !important; }

.bg-green { background-color: var(--tw-green) !important; }
.bg-green-shade { background-color: var(--tw-green-shade) !important; }
.bg-green-tint { background-color: var(--tw-green-tint) !important; }

.bg-blue { background-color: var(--tw-blue) !important; }
.bg-blue-shade { background-color: var(--tw-blue-shade) !important; }
.bg-blue-tint { background-color: var(--tw-blue-tint) !important; }

.bg-yellow { background-color: var(--tw-yellow) !important; }
.bg-yellow-shade { background-color: var(--tw-yellow-shade) !important; }
.bg-yellow-tint { background-color: var(--tw-yellow-tint) !important; }

.bg-orange { background-color: var(--tw-orange) !important; }
.bg-orange-shade { background-color: var(--tw-orange-shade) !important; }
.bg-orange-tint { background-color: var(--tw-orange-tint) !important; }

.bg-red { background-color: var(--tw-red) !important; }
.bg-red-shade { background-color: var(--tw-red-shade) !important; }
.bg-red-tint { background-color: var(--tw-red-tint) !important; }

.bg-gray { background-color: var(--tw-gray) !important; }
.bg-gray-shade { background-color: var(--tw-gray-shade) !important; }
.bg-gray-tint { background-color: var(--tw-gray-tint) !important; }

.bg-pink { background-color: var(--tw-pink) !important; }
.bg-pink-shade { background-color: var(--tw-pink-shade) !important; }
.bg-pink-tint { background-color: var(--tw-pink-tint) !important; }

.bg-purple { background-color: var(--tw-purple) !important; }
.bg-purple-shade { background-color: var(--tw-purple-shade) !important; }
.bg-purple-tint { background-color: var(--tw-purple-tint) !important; }

.bg-white { background-color: var(--tw-white) !important; }

.me-2p5 {
  margin-right: 0.75rem;
}

header {
  background-color: var(--tw-white);
  position: fixed;
  width: 100%;
  padding: 0 1.5rem;
  height: 60px;
  z-index: 99;
}

header .logo-link {
  max-width: 150px;
  position: relative;
  color: var(--tw-primary-shade);
  margin-bottom: 4px;
}

header .logo-link .position-absolute {
  position: absolute;
  right:6px;
  top:24px;
  font-size: .7rem;
}

header a, header button.link {
  font-size: 1.1rem;
}

a, button.link {
  text-decoration: none;
  color: var(--tw-primary-shade);
  background: transparent;
  font-weight: 400;
  transition: .3s color;
  border: 0;
  padding: 0;
}

a:hover, button.link:hover {
  color: #4c8583;
  transition: .5s color;
}

.breadcrumb-nav {
  --bs-breadcrumb-divider: ">";
}

.breadcrumb-nav-summary {
  --bs-breadcrumb-divider: "-";
  width: fit-content;
}

.breadcrumb-nav-summary ol {
  border-radius: 6px;
  padding: 2px 6px;
  background-color: var(--tw-primary-shade);
  font-size: 0.9rem;
}

.breadcrumb-nav-summary li {
  color: white !important;
}

.breadcrumb-nav-summary li::before {
  --bs-breadcrumb-divider-color: white;
}

.nav-underline {
  margin-top: 1rem;
}

.nav-underline .nav-link {
  color: var(--tw-gray-dark);
  font-weight: 400;
  padding: 0.375rem 0;
  margin: 0 1rem 0 0;
  font-size: 1.1rem;
  transition: none;
}

.nav-underline .nav-link:hover {
  border-bottom: 2px solid var(--tw-gray-dark);
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
  font-weight: 400;
  border-bottom: 2px solid var(--tw-primary-shade);
}

main {
  padding-top: calc(60px + 1rem);
}

.btn-primary {
  color: var(--tw-primary-shade) !important;
}

.btn-primary:focus {
  box-shadow: none;
}

.alert-sm {
  padding: 0.5rem .75rem;
    font-size: 0.875rem;
}

.invalid-feedback {
  margin-top: 0;
  padding-bottom: 4px;
  font-size: .75em;
  padding-left: 13px;
}

input:valid ~ .show-password-button {
  display: block;
}

input.is-invalid:valid {
  background-image: none;
}

.login-wrapper {
  width: 100%;
  max-width: 480px;
}

.show-password-button {
  display: none;
}

.show-password-button,
.show-password-button:hover,
.show-password-button:focus {
  color: var(--tw-gray-shade);
  background: transparent;
  position: absolute;
  right: 6px;
  top: 30px;
  transform: translateY(-50%);
  padding: 0;
  border: 0;
  width: 28px;
  height: 28px;
}

.show-password-button i {
  font-size: 1.1rem;
}

.menu-button.open i {
  transform: rotateZ(-180deg);
}

.menu-button .badge {
  margin-bottom: 2px;
}

.menu-dropdown {
  position: absolute;
  border-radius: var(--bs-border-radius);
  box-shadow: 0 0 4px 1px var(--tw-gray-tint);
  background-color: var(--tw-white);
  right: 0;
  top: 32px;
  overflow-y: auto;
  max-height: 90vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.menu-dropdown::-webkit-scrollbar {
  display: none;
}

.menu-dropdown * {
  color: var(--tw-gray-shade);
}

.menu-dropdown ul {
  list-style: none;
  padding: 0.5rem 0;
  margin-bottom: 0;
}

.menu-dropdown li {
  text-wrap: nowrap;
}

.menu-dropdown li a {
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  font-size: 1rem;
  color: var(--tw-gray-shade);
  font-weight: 300;
}

.menu-dropdown li a:hover {
  color: var(--tw-gray-shade);
}

.menu-dropdown li:hover {
  background-color: #F8F8F8;
}

.menu-dropdown .menu-split {
  height: 1px;
  background-color: var(--tw-gray-tint);
  margin: 0.5rem 1rem;
}

.menu-dropdown .menu-item-child .menu-item-child-parent {
  padding: 0 0.25rem;
}

.menu-dropdown .menu-item-child .name {
  font-weight: 500;
}

.menu-dropdown .menu-item-child>div:first-child {
min-width: 250px;
padding: 0.5rem 0;
background-color: var(--tw-primary-tint);
}

.menu-dropdown .menu-item-child>div:first-child div {
  padding-top: 8px;
}

.menu-dropdown .menu-item-child>div:first-child div a {
  font-weight: 400;
}

.menu-dropdown .menu-item-child>div:first-child div a+i {
  color: var(--tw-gray-light);
}

.menu-dropdown .menu-item-child>div:first-child div a, div span {
  font-size: 14px;
}

.dropdown .dropdown-item {
  font-weight: 300;
}

.font-small {
  font-size: 0.85rem;
}

.font-tiny {
  font-size: 0.7rem;
}

.height-content {
  height: fit-content;
}

.col-headings {
  color: rgba(33, 37, 41, 0.65);
}

.ag-root {
  --ag-header-font-size: .9rem;
  --ag-font-size: 10px !important;
}
.ag-root .ag-center-cols-container {
  min-width: 100%;
}
.ag-root .ag-header {
  border-bottom-color: var(--tw-gray);
}

.filter-item {
  width: 230px;
}

.filter-form button {
  height: 51px;
  width: 51px;
  font-size: 1.5rem;
}

.filter-form .btn-light {
  background-color: var(--tw-primary-tint);
  border-color: var(--tw-primary-tint);
  color: var(--tw-primary-shade);
}
.filter-form .btn-light:hover {
  background-color: var(--tw-primary-light);
  border-color: var(--tw-primary-light);
  color: var(--tw-primary-shade);
}

.create-product-form .products-wrapper,
.create-product-form .tab-wrapper {
  padding: 1rem;
  margin: 0;
  border-radius: var(--bs-border-radius);
  box-shadow: 0 0 .75rem #00000033;
  min-width: 100%;
  width: fit-content;
  position: relative;
}

.country-label {
  background: var(--tw-primary-tint);
  color: var(--tw-primary-shade);
  border-radius: var(--bs-border-radius);
  height: 33.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.create-product-form .products-detail-wrapper {
  width: auto !important;
}

.tab-wrapper .col, .promotion-template-summary .col {
  position: relative;
  flex: 1 1;
  width: 140px;
}
.tab-wrapper .d-flex {
  width: fit-content;
}
.tab-wrapper .col.datetime,  .promotion-template-summary .col.datetime {
  width: 190px;
}

.create-product-form {
  width: fit-content;
  margin: 0 -1.5rem;
  padding-top: 108px;
}

.create-product-form .create-product-form-header {
  position: fixed;
  background: white;
  z-index: 5;
  width: 100%;
  left: 0;
  padding: 10px 21px;
  top: 60px;
}
.create-product-form .template-wrapper {
  display: flex;
  padding-left: 0;
  padding-right: 0;
}
.create-product-form .template-wrapper>.row {
  margin:0
}

.create-product-form .template-wrapper .template-name-wrapper {
  width: 78px;
  position: sticky;
  left: 0;
  z-index: 4;
  background: white;
  box-shadow: .25rem 0 .5rem white;
}
.create-product-form .template-wrapper div.template-name {
  display: block;
  height: calc(100% - .5rem);
  min-width: 50px !important;
  max-width: 50px !important;
  margin: .5rem 0 0 1rem;
  overflow: clip;
  border-radius: var(--bs-border-radius);

}

.create-product-form .template-wrapper .row .promotion-sticky-left {
  flex: 0 0 auto;
  width: 808px;
  left: 74px;
  background: white;
  position: sticky;
  z-index: 3;
}

.create-product-form .template-wrapper .row .promotion-sticky-left.summary-section {
  width: fit-content;
  left:0;
}

.create-product-form .add-product .btn {
  position: sticky;
  left: 82px;
  z-index: 4;
}

.create-product-form .template-wrapper div.template-name + div {
 width: calc(100% - 64px);
}
.create-product-form .template-wrapper div.template-name h2 {
  position: sticky;
  top: 177px;
  height: 50px;
  vertical-align: baseline;
  display: flex;
  align-items: center;
  padding: 1rem;
  text-align: left;
  transform: translateX(-100%) rotateZ(-90deg);
  transform-origin: 100% 0;
  width: fit-content;
}

.create-product-form .tab-wrapper .heading {
  padding: 1rem 0;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tw-primary-tint);
  border-radius: 6px;
  max-height: 51px;
}

.create-product-form .error-placeholder {
  display: none !important;
}

.stock-item-header-labels {
  color: rgba(33, 37, 41, 0.65);
  font-size: 0.85rem;
}

.stock-item-header-labels>div {
  padding-left: 0.75rem;
}

.stock-item-header-labels>div:nth-child(3) {
  padding-left: 2rem;
}

.create-product-form .stock-item-group>input:first-child {
  width: 40%;
}

.create-product-form .stock-item-group>div {
  width: 60%;
}

/******* MODAL *******/

.modal-custom-wrapper {
  position: fixed;
  display: none;
  justify-content: center;
  top:0;
  left: 0;
  z-index: 999;
  width: 100dvw;
  height: 100dvh;
  opacity: 0;
  backdrop-filter: brightness(1);
  transition: opacity 5s;
}

.modal-custom-wrapper.show {
  opacity: 1;
  z-index: 999;
  display: flex;
  backdrop-filter: brightness(.5);
  transition: opacity 5s;
}

.modal-custom-wrapper .modal-custom {
  margin-top: 180px;
  width: 300px;
  height: 300px;
  border-radius: var(--bs-border-radius);
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-custom-wrapper .modal-custom i.bi {
  font-size: 5rem;
}

.w-5  { width: 5%; }
.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-35 { width: 35%; }
.w-40 { width: 40%; }
.w-45 { width: 45%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%; }
.w-65 { width: 65%; }
.w-70 { width: 70%; }
.w-75 { width: 75%; }
.w-80 { width: 80%; }
.w-85 { width: 85%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-100 { width: 100%; }


input[type="number"] {
  padding-right: 0 !important
}

.autocomplete-dropdown {
  position: absolute;
  margin-top: 10px;
  background: var(--tw-white);
  border-radius: var(--bs-border-radius);
  z-index: 99;
  box-shadow: 0 0 4px 1px var(--tw-gray-light);
  padding: 0.5rem 0;
  list-style: none;
  width: fit-content;
}

.autocomplete-dropdown-content {
  background: transparent;
  width: 100%;
  white-space: nowrap;
  border: none;
  text-align: left;
  min-width: fit-content;
}

.autocomplete-dropdown-content:hover,
.autocomplete-dropdown-content:focus,
.autocomplete-dropdown-content:focus-visible {
  background: var(--tw-primary-tint);
  border: 0;
}

.stock-item-dropdown {
  width: 400px !important;
}

.btn-outline-primary {
  color: var(--tw-primary-shade);
  border-color: var(--tw-primary-shade);
}

.btn-outline-primary {
  color: var(--tw-primary-shade);
  border-color: var(--tw-primary-shade);
}

.btn-outline-primary:hover {
  color: var(--tw-primary-shade);
}

.btn-icon,
.btn-icon:hover,
.btn-icon:focus {
  background-color: transparent;
  border: 0;
  color: var(--tw-primary-shade);
}

.promotion-template-summary {
  display:flex; flex-wrap: nowrap;
}

.promotion-template-summary .summary-section>div {
  background-color: var(--tw-primary);
  border-radius: var(--bs-border-radius);
}

.promotion-template-summary .summary-section {
  width: fit-content;
}


.promotion-template-summary .summary-section input {
  background-color: var(--tw-primary-tint);
  color: var(--tw-primary-shade);
  border: 0;
}

.promotion-template-summary .summary-section input[value="0.00"] {
  color: var(--tw-gray);
}

.promotion-saving-alert {
  position: fixed;
  top: 180px;
  z-index: 10;
  margin: 3rem;
  left: 5rem;
}

.form-floating label {
  max-width: calc(100% / .85);
  text-overflow: ellipsis;
}

.summary-section .form-floating label {
  max-width: 100%;
}


.form-floating .tooltip-trigger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 24px;
  cursor: initial;
}

.promotion-template-summary .summary-section .form-floating label {
  color: var(--tw-primary-shade);
}

.promotion-template-summary .summary-section .form-floating label::after {
  background-color: var(--tw-primary-tint);
}

.summary-template-name {
  width: 200px;
}

.summary-template-name h2 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--tw-primary-shade);
}

.btn-group .btn-outline-primary.active {
  background-color: var(--tw-primary-shade);
  color: var(--tw-white) !important;
  border-color: var(--tw-primary-shade);
}

input[type="datetime-local"][value=""] {
    color: var(--tw-gray-light);
}
input[type="datetime-local"], input[type="datetime-local"]:focus {
    color: var(--tw-gray-shade);
}

.hide-column-btn {
  width: 17%;
  position: absolute;
  top: 0;
  right: 0.25rem;
  color: var(--tw-primary-shade);
  background-color: var(--tw-primary-tint);
  border-radius: var(--bs-border-radius);
  padding: 2px 4px;
}

.hide-column-btn:hover {
  background-color: var(--tw-primary-tint);
}

.dropdown-menu {
  max-height: 80vh;
  overflow-y: auto;
  border: 0;
  box-shadow: 0 0 .75rem #00000033;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-toggle.show i.bi-chevron-down {
  transform: rotate(-180deg);
}

.grouping-dropdown button {
  width: 144px;
  display: flex;
  justify-content: space-between;
}

.view-level-dropdown button {
  width: 136px;
  display: flex;
  justify-content: space-between;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none;
}

.layout-selector {
  width: calc(100% - 32px);
  flex: 0 0 auto;
  text-wrap: wrap;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.layout-trash {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#dropdown-layout span {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.saving-lock .promotion-sticky-left, .saving-lock .products-detail-wrapper {
  opacity: .5;
}

.toast {
  opacity: .9;
  font-size: 1rem;
}
.toast:hover {
  opacity: 1;
  cursor: pointer;
}

.toast .toast-header {
  background-color: #ffffff22;
  color: inherit;
}

.toast .btn-close {
  opacity: 1;
}

.toast.toast-warning .toast-header {
  border-bottom-color: var(--tw-yellow);
}
.toast.toast-error .toast-header {
  border-bottom-color: var(--tw-red);
}

.toast small {
  font-size: 8px;
  color: var(--tw-gray-shade);
}

.saving-lock .loading-spinner {
  width: 670px;
  position: absolute;
  z-index: 999;
  padding: 2.4rem 0;
  left: 0;
}

.notifications {
  right:0;
  left: unset;
}

.cursor-pointer {
  cursor: pointer;
}

.percentage {
  border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);
  display: inline-block;
  width: 75%;
}

.percentage input {
  border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);
  width: 100%;
}

.percentage+div {
  width: 25%;
  display: inline-flex;
  align-items: end;
  justify-content: center;
  border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0;
  background-color: #e9ecef;
  color: var(--tw-primary-shade);
  border: 1px solid #dee2e6;
  border-left: 0;
  height: 100%;
  font-weight: 600;
  vertical-align: top;
  padding-bottom: 7px;
}

.summary-section .percentage+div {
  height: 51px;
  background-color: var(--tw-primary-tint);
  border-left: 1px solid var(--tw-primary);
  border-bottom: 1px solid var(--tw-primary-tint);
  border-top: 1px solid var(--tw-primary-tint);
  border-right: 1px solid var(--tw-primary-tint);
}

.btn-calculate-values {
  padding: 0;
  width: 40px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-info-wrapper>div>div {
  margin-right: 0.75rem;
}

#dropdown-language .dropdown-toggle {
  border: 0;
}

#dropdown-language img {
  box-shadow: 0 1px 4px #000000aa;
  border-radius: 50%;
  margin-right: 8px
}

.names-linked-toggle {
  right: 0;
}

.price-reset-toggle {
  left: 8px;
  top: 1px;
}
.names-linked-toggle, .translate-product-toggle, .price-reset-toggle {
  position: absolute;
  z-index: 2;
  padding-top: 3px;
}

/*.stock-item-form-country .names-linked-toggle {*/
/*  left: -8px;*/
/*  right: auto;*/
/*}*/

.data-save {
  width: 51px;
  aspect-ratio: 1;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.outside-floating-label {
  font-size: .85rem;
  color: rgba(33, 37, 41, 0.65);
  padding: .5rem;
  vertical-align: bottom;
}

.faded {
  opacity: .5;
  pointer-events: none;
}

.add-template-btn-wrapper {
  width: 78px;
  position: sticky;
  left: 0;
  z-index: 4;
  background: white;
  box-shadow: .25rem 0 .5rem white;
  padding-left: 1rem;
  margin-bottom: 3rem;
}

.add-template-btn-wrapper button {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-item-code-trigger {
  width: 40% !important;
  text-align: start;
}

.create-product-form input.border-warning {
  box-shadow: 0 0 4px var(--tw-yellow);
  color: var(--tw-yellow-shade);
}

.promotion-week-input {
  max-width: 90px;
}

.promotion-header>div:first-child,
.promotion-header>div:first-child .btn-primary {
  max-height: 51px;
}

.week-summary {
  display: flex;
  padding: 8px;
  background-color: var(--tw-primary-tint);
  border-radius: var(--bs-border-radius);
}

.week-summary>div {
  padding: 8px;
}

.week-summary-data {
  background-color: var(--tw-white);
  border-radius: 6px;
  margin: 0 4px;
  min-width: 73px;
}

.week-summary-data:last-child {
  margin-right: 0;
}

.week-summary-data.total {
  border: 1px solid var(--tw-primary);
}

.total-gp-perc {
  padding-top: 16px;
}

.instead-of-save-btn {
  width: 57px;
}

.week-summary .btn-icon:disabled {
  opacity: 0;
}

.w-fit {
  width: fit-content;
}

.input-group>:not(:first-child)>input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.i_business-select:disabled {
  background: var(--tw-primary-tint);
  border-color: var(--tw-primary-tint);
}

.i_business-select:disabled + label {
  color: var(--tw-primary-shade) !important;
  font-weight: 500;
}

.i_business-select:disabled + label::after {
  background-color: var(--tw-primary-tint) !important;
}

.closed-product-fade {
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0.5;
}

.closed-product-label {
  position: absolute;
  bottom: 25px;
  right: -4px;
  font-weight: 500;
  color: var(--tw-red-dark);
  transform: rotateZ(-90deg);
  text-transform: uppercase;
  z-index: 3;
  font-size: 1.2rem;
}

.w-60 {
  width: 60%;
}

.product-options-dropdown {
  margin-top: -.75rem;
}

.status-dropdown button {
  border: 1px solid var(--bs-border-color) !important;
  min-width: 128px;
  color: var(--tw-gray-shade);
  background-color: var(--tw-white) !important;
  transition: none;
}

.status-dropdown button .status-label {
  color: rgba(33, 37, 41, 0.65);
  font-weight: 300;
}

.status-label {
  font-size: 0.85rem;
  text-align: left;
}

.error-icon svg {
  width: 96px;
  height: 96px;
}

.layout-heading {
  font-size: 0.89rem;
  color: var(--tw-gray-dark);
}

.homepage-buttons-wrapper .btn {
  width: 230px;
}

.orders-upload-button {
  width: 144px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.orders-upload-button i {
  font-size: 2rem;
}

.orders-upload-modal-trigger {
  width: fit-content;
  margin-left: 0.75rem;
}

.approval-status {
  font-size: .85rem;
}

.modal-faded {
  z-index: 99;
}

.save-tupperware-europe {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.save-tupperware-europe form {
  max-width: 600px;
  width: 100%;
}

.save-tupperware-europe .btn {
  padding: 10px 50px;
  margin-bottom: 3rem;
  font-size: 2rem;
}

.save-tupperware-europe textarea {
  min-height: 200px !important;
}

.save-tupperware-europe .form-check {
  text-align: left;
  margin-top: 1rem;
}

.save-tupperware-europe .form-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}

.save-tupperware-europe h1 {
  font-size: 3.25rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3rem;
}
.save-tupperware-europe h1 span {
  color: var(--tw-primary-shade);
  font-size: 3.25rem;
  font-weight: 600;
}
.save-tupperware-europe h2 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5;
}
.save-tupperware-europe h2 strong {
  color: var(--tw-primary-shade);
}
.save-tupperware-europe p {
  font-size: 1.45rem;
  line-height: 1.75;
  margin-bottom: 2rem;
}
.save-tupperware-europe p span {
  font-size: 1.45rem;
  line-height: 1.75;
  margin-bottom: 2rem;
  color: var(--tw-primary-shade);
}
.save-tupperware-europe strong {
  font-weight: 600;
  color: black;
}

.save-tupperware-europe .text-wrapper, .save-tupperware-europe .signed-wrapper {
  max-width: 1000px;
    width: 100%;
  text-align: center;
}

.save-tupperware-europe .kpis {
  padding: 6rem 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 600px;
  justify-self: center;
}

.save-tupperware-europe .kpis div {
  text-align: center;
}
.save-tupperware-europe .kpis div span.number {
  font-size: 3rem;
  font-weight: 600;
  display: block;
  line-height: 3rem;
  color: var(--tw-primary-shade);
}
.save-tupperware-europe .kpis div span.type {
  font-size: 1.2rem;
  font-weight: 300;
}

span.form-required::after {
  content: " * ";
  font-weight: 600;
  color: var(--tw-red);
}

.btn.fab {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5000px !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.btn-round {
  border-radius: 5000px !important;
  padding: 0 !important;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.save-tupperware-europe .share-wrapper {
  margin-bottom: 10rem;
}

.save-tupperware-europe .share-wrapper h2 {
  font-weight: 500;
}

.save-tupperware-europe .share-wrapper .col {
  display: flex;
  align-items: center;
  justify-content: center;
    flex-direction: column;
  min-width: 100px;
}

#lets-unite {
  padding-top: 4rem;
  margin-top: 2rem;
}


.btn-pink {
  color: white !important;
  background-color: var(--tw-pink) !important;
}


.messages-wrapper {
  display: flex;
  justify-content: start;
  width: 100%;
  margin-bottom: 6rem;
  overflow: hidden;
}

.messages-wrapper .message {
  /*width: 300px;*/
  /*min-width: 300px;*/
  /*min-height: 200px;*/
  font-size: 1.1rem;
  padding: 15px;
  /*border: none;*/
  /*box-shadow: 0 0 10px 1px var(--tw-gray-tint);*/
}
.messages-wrapper .swiper {
  padding-bottom: 40px !important;
  display: block;
  margin: 0;
  width: 100%;
}
.messages-wrapper .swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--tw-primary-shade);
}

.messages-wrapper .message .author {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  color: var(--tw-primary-shade);
}
